import { FunctionComponent, useEffect } from "react"
import Modal from "./shared/Modal"
import Checkbox from "./Checkbox"
import { AdvancedSearchFields } from "../hooks/useSearchPageStateAndHandlers"
import Divider from "./shared/Divider"

interface Props {
    closeModal: (b: boolean) => void;
    setAdvSearchState: (s: string, b: boolean) => void;
    advSearchState: AdvancedSearchFields;
    clearAdvSearchState: () => void;
}

const BaseJurisdictions: { [key: string]: string } = {
    ACT: "Australian Capital Territory",
    NSW: "New South Wales",
    NT: "Northern Territory",
    Qld: "Queensland",
    SA: "South Australia",
    Tas: "Tasmania",
    Vic: "Victoria",
    WA: "Western Australia",
    HCA: "High Court of Australia",
    FCA: "Federal Court of Australia",
    FedCFamC1A: "Federal Circuit and Family Court",
    FamCAFC: "Family Court",
}

const StateJurisdictionsFull = {
    ACT: [
        ["Court of Appeal", "ACTCA", true],
        ["Supreme Court", "ACTSC", true],
    ],
    NSW: [
        ["Court of Appeal", "NSWCA", true],
        ["Court of Criminal Appeal", "NSWCCA", true],
        ["Supreme Court", "NSWSC", true],
    ],
    NT: [
        ["Court of Appeal", "NTCA", true],
        ["Court of Criminal Appeal", "NTCCA", false],
        ["Full Court", "NTSCFC", false],
        ["Supreme Court", "NTSC", true],
    ],
    Qld: [
        ["Court of Appeal", "QCA", true],
        ["Supreme Court", "QSC", true],
    ],
    SA: [
        ["Court of Appeal", "SASCA", true],
        ["Full Court", "SASCFC", true],
        ["Supreme Court", "SASC", true],
    ],
    Tas: [
        ["Full Court", "TASFC", true],
        ["Court of Criminal Appeal", "TASCCA", false],
        ["Supreme Court", "TASSC", true],
    ],
    Vic: [
        ["Court of Appeal", "VSCA", true],
        ["Supreme Court", "VSC", true],
    ],
    WA: [
        ["Court of Appeal", "WASCA", true],
        ["Supreme Court", "WASC", true],
    ],
}

const FederalJurisdictionsFull = {
    HCA: [],
    FCA: [
        ["Full Court", "FCAFC", true],
        ["Federal Court", "FCA", true],
    ],
    FedCFamC1A: [
        ["Division 1 Appellate Jurisdiction", "FedCFamC1A", true],
        ["Division 1 First Instance", "FedCFamC1F", false],
        ["Division 2 Family Law", "FedCFamC2F", false],
        ["Division 2 General Federal Law", "FedCFamC2G", false],
    ],
    FamCAFC: [["Full Court", "FamCAFC", true]],
}

const ReportedJurisdictionsFull = {
    VR: [["Victorian Reports", "VR", true]],
}

const AdvancedSearchModal: FunctionComponent<Props> = ({
    closeModal,
    setAdvSearchState,
    advSearchState,
    clearAdvSearchState,
}) => {
    return (
        <Modal
            title='Advanced Search'
            dismiss={"Apply"}
            noRevert={false}
            closeModal={closeModal}
            clear={clearAdvSearchState}
        >
            <div className='flex flex-col lg:flex-row lg:space-x-16'>
                <div className='flex flex-col'>
                    <h1 className='font-bold mb-2 text-xl'>State</h1>
                    <div className='w-48 border-t border-gray-200 mb-2' />
                    {Object.entries(StateJurisdictionsFull).map(([name, j1]: [string, any], idx: number) => (
                        <>
                            <div className='font-bold mb-2 text-sm'>
                                {BaseJurisdictions[name]}
                                {/* <Checkbox
                                    key={idx}
                                    mnc={name}
                                    label={BaseJurisdictions[name]}
                                    setAdvSearchState={setAdvSearchState}
                                    advSearchState={advSearchState}
                                /> */}
                            </div>
                            <div className='ml-4'>
                                {j1.map(([label, mnc, enabled]: string[]) => (
                                    <Checkbox
                                        key={idx}
                                        mnc={mnc}
                                        label={`${label} (${mnc})`}
                                        setAdvSearchState={setAdvSearchState}
                                        advSearchState={advSearchState}
                                        disabled={!enabled}
                                    />
                                ))}
                            </div>
                        </>
                    ))}
                </div>
                <div className='flex flex-col'>
                    <h1 className='font-bold mb-2 text-xl'>Federal</h1>
                    <div className='w-48 border-t border-gray-200 mb-2' />
                    {Object.entries(FederalJurisdictionsFull).map(([name, j1]: any, idx: number) => (
                        <>
                            <div className='font-bold'>
                                <Checkbox
                                    key={idx}
                                    mnc={name}
                                    label={BaseJurisdictions[name]}
                                    setAdvSearchState={setAdvSearchState}
                                    advSearchState={advSearchState}
                                />
                            </div>
                            <div className='ml-4'>
                                {j1.map(([label, mnc, enabled]: string[]) => (
                                    <Checkbox
                                        key={idx}
                                        mnc={mnc}
                                        label={`${label} (${mnc})`}
                                        setAdvSearchState={setAdvSearchState}
                                        advSearchState={advSearchState}
                                        disabled={!enabled}
                                    />
                                ))}
                            </div>
                        </>
                    ))}
                </div>
                {/* <div className='flex flex-col'>
                    <h1 className='font-bold mb-2 text-xl'>Reported Series</h1>
                    <div className='w-48 border-t border-gray-200 mb-2' />
                    {Object.entries(ReportedJurisdictionsFull).map(([_name, j1]: any, idx: number) => (
                        <>
                            <div className=''>
                                {j1.map(([label, mnc, enabled]: string[]) => (
                                    <Checkbox
                                        key={idx}
                                        mnc={mnc}
                                        label={`${label} (${mnc})`}
                                        setAdvSearchState={setAdvSearchState}
                                        advSearchState={advSearchState}
                                        disabled={!enabled}
                                    />
                                ))}
                            </div>
                        </>
                    ))}
                </div> */}
            </div>
        </Modal>
    )
}

export default AdvancedSearchModal

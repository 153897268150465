import { FunctionComponent } from "react"
import { AdvancedSearchFields } from "../hooks/useSearchPageStateAndHandlers"

declare global {
    namespace JSX {
        interface IntrinsicElements {
            ["checkbox"]: unknown;
            ["box"]: unknown;
        }
    }
}

interface Props {
    label: string;
    mnc: string;
    setAdvSearchState: (s: string, b: boolean) => void;
    advSearchState: AdvancedSearchFields;
    disabled?: boolean;
}

const Checkbox: FunctionComponent<Props> = ({ label, mnc, setAdvSearchState, advSearchState, disabled }) => {
    return (
        <checkbox is='div'>
            <div className={`flex items-center mb-4 ${disabled ? "opacity-50" : null}`}>
                <input
                    key={Math.random()}
                    id='default-checkbox'
                    type='checkbox'
                    value={mnc}
                    checked={advSearchState[mnc]}
                    onChange={() => setAdvSearchState(mnc, !advSearchState[mnc])}
                    className='w-4 h-4 bg-white border-gray-400 border-2 text-jade-primary focus:outline-0 focus:ring-jade-primary hover:bg-unfocussed-light hover:cursor-pointer transition duration-200'
                    disabled={disabled}
                />
                <label
                    onClick={() => (!disabled ? setAdvSearchState(mnc, !advSearchState[mnc]) : null)}
                    className='ml-4 text-sm text-gray-900 font-inter hover:cursor-pointer hover:text-jade-secondary'
                >
                    {label}
                </label>
            </div>
        </checkbox>
    )
}

export default Checkbox

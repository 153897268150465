import React, { FunctionComponent } from "react"
import FocusWrap from "./FocusWrap"
import Modal from "./Modal"

interface PrivacyStatementModalProps {
    closeModal: (state: boolean) => void;
}

export const PrivacyStatementModal: FunctionComponent<PrivacyStatementModalProps> = (props) => {
    return (
        <Modal title='Search History' dismiss='Close' noRevert={false} {...props}>
            <div className={`p-6 font-inter`}>
                <p className={`text-base text-gray-500`}>
                    At JADE, we value transparency in the way your data is used. JADE encourages the ethical use of user
                    data and does its best to ensure safety and security in all software that we provide. We strive to
                    promote an open and safe environment for starting your legal research journey.
                </p>
                <p className={`mt-6 text-base text-gray-500`}>
                    We do not store your results or search history after your query is complete.
                </p>
                <p className={`text-base text-gray-500`}>Every search is encrypted between your browser and Jasmine.</p>
                <p className={`mt-6 text-base text-gray-500`}>Ask away, there are no silly questions!</p>
            </div>
        </Modal>
    )
}

export default PrivacyStatementModal

import React, { FunctionComponent, ReactNode } from "react"
import FocusWrap from "./FocusWrap"

interface Props {
    title: string;
    dismiss: string;
    noRevert: boolean;
    clear?: () => void;
    closeModal: (b: boolean) => void;
    children: ReactNode;
}

const Modal: FunctionComponent<Props> = ({ title, closeModal, noRevert, children, dismiss, clear }) => {
    return (
        <FocusWrap shouldFocus={true}>
            <div className={`relative bg-white md:rounded-lg lg:rounded-lg shadow`}>
                <div className={`flex justify-between items-start p-4 rounded-t`}>
                    <h3 className={`m-1 ml-2 text-xl font-semibold text-gray-900`}>{title}</h3>
                    {!noRevert && (
                        <button
                            type='button'
                            onClick={() => closeModal(false)}
                            className={`text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center`}
                        >
                            <svg aria-hidden='true' className={`w-5 h-5`} fill='currentColor' viewBox='0 0 20 20'>
                                <path
                                    fillRule='evenodd'
                                    d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                                    clipRule='evenodd'
                                ></path>
                            </svg>
                        </button>
                    )}
                </div>
                <div className={`p-6`}>{children}</div>
                <div className='flex flex-row justify-between'>
                    <div className={`flex items-center p-6 space-x-2 rounded-b flex-row-reverse`}>
                        {clear && (
                            <button
                                onClick={() => clear()}
                                className='rounded relative inline-flex group items-center justify-center px-4 py-3 m-1 cursor-pointer active:border-jade-primary active:shadow-none shadow-lg bg-jade-secondary border-jade-primary text-white'
                            >
                                <span className='absolute w-0 h-0 transition-all duration-300 ease-out bg-white rounded-full group-hover:w-24 group-hover:h-24 opacity-10'></span>
                                <span className='relative text-white transition duration-300 group-hover:text-white ease'>
                                    Clear
                                </span>
                            </button>
                        )}
                    </div>
                    <div className={`flex items-center p-6 space-x-2 rounded-b flex-row-reverse`}>
                        <button
                            onClick={() => closeModal(false)}
                            className='rounded relative inline-flex group items-center justify-center px-4 py-3 m-1 cursor-pointer active:border-jade-primary active:shadow-none shadow-lg bg-gradient-to-tr bg-jade-primary border-jade-primary text-white'
                        >
                            <span className='absolute w-0 h-0 transition-all duration-300 ease-out bg-white rounded-full group-hover:w-24 group-hover:h-24 opacity-10'></span>
                            <span className='relative text-white transition duration-300 group-hover:text-white ease'>
                                {dismiss}
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </FocusWrap>
    )
}

export default Modal

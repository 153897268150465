import { useState } from "react";
import { useRouter } from "next/router";
import { AuthStatus, useAuthStore } from "../stores/useAuthStore";

type SearchPageStateAndHandlersDefinition = () => SearchPageRoutingHandlersAndState;

type SearchPageRoutingHandlersAndState = {
    authenticated: boolean;
    handleRunSearch: () => void;
    handleTogglePrivacyModal: (state: boolean) => void;
    handleSetQuery: (query: string, isSuggestion?: boolean) => void;
    handleToggleAdvModal: (state: boolean) => void;
    query: string;
    showPrivacyModal: boolean;
    showAdvSearchModal: boolean;
    advancedSearchState: AdvancedSearchFields;
    handleSetAdvSearchState: (f: string, v: boolean) => void;
    clearAdvSearchState: () => void;
};

export type AdvancedSearchFields = Record<string, boolean>;

const useSearchPageStateAndHandlers: SearchPageStateAndHandlersDefinition = () => {
    // useAuthStore().checkAuthStatus();
    const authenticated = useAuthStore((state) => state.authStatus === AuthStatus.Authenticated);

    const router = useRouter();
    const [query, setQuery] = useState<string>("");
    const [showPrivacyModal, setShowPrivacyModal] = useState<boolean>(false);
    const [showAdvSearchModal, setAdvSearchModal] = useState<boolean>(false);
    const [advancedSearchState, setAdvancedSearchState] = useState<AdvancedSearchFields>({});

    const handleRunSearch = (q?: string, searchType?: number) => {
        const method = searchType === 0 ? "semantic" : "keyword";
        const query = Object.entries(advancedSearchState).reduce((acc, [k, v]) => `${acc}${v ? `${k},` : ""}`, "");

        router
            .push(`/search/${encodeURI(q ?? query).replaceAll("?", "")}?filters=${query}&method=${method}`)
            .catch(() => null);
    };

    const handleSetQuery = (query: string) => {
        setQuery(query);
        handleRunSearch(query);
    };

    const handleTogglePrivacyModal = (state: boolean) => setShowPrivacyModal(state);
    const handleToggleAdvModal = (state: boolean) => setAdvSearchModal(state);
    const handleSetAdvSearchState = (field: string, value: boolean) => {
        const stateCpy = JSON.parse(JSON.stringify(advancedSearchState));
        stateCpy[field] = value;
        setAdvancedSearchState(stateCpy);
    };
    const clearAdvSearchState = () => setAdvancedSearchState({} as AdvancedSearchFields);

    return {
        authenticated,
        handleRunSearch,
        handleTogglePrivacyModal,
        handleSetQuery,
        handleToggleAdvModal,
        query,
        showPrivacyModal,
        showAdvSearchModal,
        advancedSearchState,
        handleSetAdvSearchState,
        clearAdvSearchState,
    };
};

export default useSearchPageStateAndHandlers;
